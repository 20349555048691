/* eslint-disable camelcase */
/* eslint-disable no-tabs */
/* eslint-disable no-mixed-spaces-and-tabs */
import React, { useRef, useEffect } from "react";
import * as am4charts from "@amcharts/amcharts4/charts";
import * as am4core from "@amcharts/amcharts4/core";
import PropTypes from "prop-types";
import { total_out_colors_graph } from "../../assets/GloableColor";
import { Divider, Typography } from "@material-ui/core";
import Grid from "@material-ui/core/Grid";
import CardFooter from "components/Card/CardFooter";
import CardBody from "components/Card/CardBody";
import CardHeader from "components/Card/CardHeader";
import { IconButton } from "@material-ui/core";
import { Menu } from "@material-ui/core";
import { MenuItem } from "@material-ui/core";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";
import currentBalance from "assets/jss/material-dashboard-react/views/currentBalance";
import { makeStyles } from "@material-ui/core/styles";
// import ArrowCircleDownIcon from '@material-ui/icons/ArrowCircleDown';
// import ArrowCircleDownTwoToneIcon from '@material-ui/icons/ArrowCircleDownTwoTone';

const options = ["None", "Atria", "Callisto", "Dione"];
const ITEM_HEIGHT = 48;

const useStyles = makeStyles(currentBalance);

function Daliydeposits(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const classes = useStyles();
  useEffect(() => {
    if (document.getElementById("totalout") !== null) {
      const chart = am4core.create("totalout", am4charts.XYChart);
      chart.logo.disabled = true;
      chart.colors.step = 2;
      chart.maskBullets = false;
      const title = chart.titles.create();
      title.text = "Total OutStanding";
      title.fontSize = 13;
      title.fontFamily = "Source Sans Pro";
      title.paddingBottom = 10;
      title.align = "left";
      title.fontWeight = "bold";

      if (
        props.dashboardData.totalOutStandingCount &&
        props.dashboardData.totalOutStandingCount.data
      ) {
        // Add data
        chart.data = props.dashboardData.totalOutStandingCount.data[0]?.data;
      }
      const dateAxis = chart.xAxes.push(new am4charts.DateAxis());
      // dateAxis.renderer.labels.template.location = 20;
      dateAxis.renderer.minGridDistance = 0;
      dateAxis.renderer.grid.template.disabled = true;
      dateAxis.renderer.fullWidthTooltip = true;
      dateAxis.renderer.labels.template.rotation = 315;
      dateAxis.renderer.labels.template.verticalCenter = "right";
      dateAxis.renderer.labels.template.fontSize = am4core.percent(65);
      dateAxis.tooltip.disabled = true;
      dateAxis.renderer.labels.template.url = "/admin/totalOutStanding";
      dateAxis.renderer.labels.template.urlTarget = "_self";

      const distanceAxis = chart.yAxes.push(new am4charts.ValueAxis());
      distanceAxis.title.text = "Volume ($)";
      distanceAxis.title.fontSize = am4core.percent(65);
      distanceAxis.renderer.grid.template.disabled = true;
      distanceAxis.renderer.labels.template.fontSize = am4core.percent(65);
      distanceAxis.tooltip.disabled = true;

      const durationAxis = chart.yAxes.push(new am4charts.ValueAxis());
      durationAxis.title.text = "Count";
      durationAxis.title.fontSize = am4core.percent(65);
      durationAxis.baseUnit = "total_count";
      durationAxis.renderer.labels.template.fontSize = am4core.percent(65);
      durationAxis.renderer.grid.template.disabled = true;
      durationAxis.renderer.opposite = true;
      durationAxis.syncWithAxis = distanceAxis;
      durationAxis.tooltip.disabled = true;

      // durationAxis.durationFormatter.durationFormat = "hh'h' mm'min'";

      // Create series
      const distanceSeries = chart.series.push(new am4charts.ColumnSeries());
      distanceSeries.dataFields.valueY = "total_amount";
      distanceSeries.dataFields.dateX = "month_year";
      distanceSeries.yAxis = distanceAxis;

      //    distanceSeries.tooltipText = "{valueY}";
      distanceSeries.name = "Volume($)";
      distanceSeries.fontSize = am4core.percent(70);
      distanceSeries.columns.template.width = am4core.percent(50);
      distanceSeries.fill = total_out_colors_graph.volume;
      distanceSeries.stroke = total_out_colors_graph.volume;
      distanceSeries.columns.template.fillOpacity = 8;
      distanceSeries.columns.template.propertyFields.strokeDasharray =
        "dashLength";
      distanceSeries.columns.template.propertyFields.fillOpacity = "alpha";
      distanceSeries.showOnInit = true;

      const distanceState =
        distanceSeries.columns.template.states.create("hover");
      distanceState.properties.fillOpacity = 0.9;

      const durationSeries = chart.series.push(new am4charts.LineSeries());
      durationSeries.dataFields.valueY = "total_count";
      durationSeries.dataFields.dateX = "month_year";
      durationSeries.name = "Count";
      durationSeries.yAxis = durationAxis;
      durationSeries.strokeWidth = 1;
      durationSeries.propertyFields.strokeDasharray = "dashLength";
      // durationSeries.tooltipText = "{valueY}";
      // durationSeries.tooltipText = "[bold]{date}[\]\n[font-size:13px]Count: [/][font-size:11px]{total_count}\n[font-size:13px]{name} = [/][font-size:11px]{valueY}";
      durationSeries.tooltipHTML = `<table style="font-size:10px">
		 <tr>
		 <center style="font-size:10px"><strong>{month_year}</strong></center>
		 <hr />
	   </tr>
	  
		 <tr>
		   <th align="left">Count:</th>
		   <td>{total_count}</td>
		 </tr>
		 <tr>
		   <th align="left">Volume($):</th>
		   <td>{total_amount}</td>
		 </tr>
		 
		 </table>`;
      durationSeries.showOnInit = true;
      durationSeries.stroke = total_out_colors_graph.line;
      durationSeries.fill = total_out_colors_graph.line;

      const durationBullet = durationSeries.bullets.push(
        new am4charts.Bullet()
      );
      const durationRectangle = durationBullet.createChild(am4core.Rectangle);
      durationBullet.horizontalCenter = "middle";
      durationBullet.verticalCenter = "middle";
      const circleBullet = durationSeries.bullets.push(
        new am4charts.CircleBullet()
      );
      circleBullet.circle.radius = 2;

      const durationState = durationBullet.states.create("hover");
      durationState.properties.scale = 1.2;

      // Add legend
      chart.legend = new am4charts.Legend();
      chart.legend.position = "top";
      chart.legend.fontSize = 10;
      chart.legend.useDefaultMarker = true;
      const marker = chart.legend.markers.template.children.getIndex(0);
      marker.cornerRadius(12, 12, 12, 12);
      marker.strokeWidth = 0;
      marker.strokeOpacity = 1;

      chart.tooltip.label.fill = am4core.color("#f00");

      const markerTemplate = chart.legend.markers.template;
      markerTemplate.width = 10;
      markerTemplate.height = 10;

      chart.tooltip.getFillFromObject = true;
      chart.tooltip.background.fill = am4core.color("#67b7dc");

      // Add cursor
      durationSeries.tooltip.dy = -8;

      chart.cursor = new am4charts.XYCursor();
      chart.cursor.fullWidthLineX = true;
      chart.cursor.xAxis = dateAxis;

      chart.cursor.lineX.strokeOpacity = 0;
      chart.cursor.lineX.fill = total_out_colors_graph.total_amount;
      chart.cursor.lineX.fillOpacity = 0.1;
      chart.cursor.lineY.disabled = true;

      const hoverState = distanceSeries.columns.template.states.create("hover");
      hoverState.properties.fill = am4core.color(
        total_out_colors_graph.columnHover
      );
      hoverState.properties.fillOpacity = 0.5;

      const buttonContainer = chart.plotContainer.createChild(
        am4core.Container
      );
      buttonContainer.shouldClone = false;
      buttonContainer.align = "right";
      buttonContainer.zIndex = Number.MAX_SAFE_INTEGER;
      buttonContainer.layout = "horizontal";
    }
  }, [props.dashboardData.totalOutStandingCount]);
  return (
    <>
      <Grid
        item
        // className={classes.cardGrid}
      >
        <CardHeader
          className={classes.duesGrid}
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            paddingBottom: "12px",
            paddingTop: "12px",
          }}
        >
          <Typography noWrap className={classes.cardTitle}>
            Outstanding
          </Typography>
        </CardHeader>

        {props.dashboardData.totalOutStandingCount &&
          props.dashboardData.totalOutStandingCount.data.map((row) => (
            <CardBody>
              {props.dashboardData.totalOutStandingCount !== 0 ? 
              <Typography className={classes.typographyAmmount}>
                {row.monthYear}
              </Typography> : ""}
              <Typography className={classes.typographyCount}>
                ${55}
              </Typography>
            </CardBody>
          ))}
        <CardBody>
          <Typography className={classes.typographyCount}>
            $903.36
          </Typography>
        </CardBody>
        <CardFooter stats>
          <div
            className={classes.viewDetailOut}
            align="left"
            // onClick={(event) => props.handleChange(event, 1)}
          >
            {/* <ArrowCircleDownTwoToneIcon></ArrowCircleDownTwoToneIcon>  */}
            <svg
              width="18"
              height="18"
              viewBox="0 0 18 18"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 8.6925L13.0575 9.75L9 13.8075L4.9425 9.75L6 8.6925L8.25 10.9425V4.5H9.75V10.9425L12 8.6925ZM18 9C18 13.965 13.965 18 9 18C4.035 18 0 13.965 0 9C0 4.035 4.035 0 9 0C13.965 0 18 4.035 18 9ZM16.5 9C16.5 4.8675 13.1325 1.5 9 1.5C4.8675 1.5 1.5 4.8675 1.5 9C1.5 13.1325 4.8675 16.5 9 16.5C13.1325 16.5 16.5 13.1325 16.5 9Z"
                fill="#CF4270"
              />
            </svg>
            2.5%
          </div>
          <div className={classes.stats} />
        </CardFooter>
      </Grid>
    </>
  );
}

Daliydeposits.propTypes = {
  dashboardData: PropTypes.object,
};

export default Daliydeposits;
